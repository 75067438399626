import React, { useEffect } from "react";
import "../../css/main.css";
import { Button } from "@dnb/eufemia/components";
import { H1, H3 } from "@dnb/eufemia";
import { link_out as linkOut } from "@dnb/eufemia/icons";
import { LOGIN_URL } from "../../config";
import { useQuery, useQueryClient } from "react-query";
import { fetchAssignments, Assignment } from "../../api/Assignment";
import { navigate } from "gatsby";
import { LOGOUT_URL } from "../../config";

const LandingContent = () => {
  const queryClient = useQueryClient();
  const assignments = useQuery<Assignment[], Error>(
    `assignments`,
    () => fetchAssignments(),
    { retry: false }
  );

  const logout = () => {
    sessionStorage.clear();
    if (typeof window !== `undefined`) {
      localStorage.removeItem(`user_name`);
    }
    window.location.assign(LOGOUT_URL);
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (assignments?.isSuccess) {
        navigate("/table/");
      } else if (assignments?.isError && localStorage.getItem(`user_name`)) {
        logout();
      }
    }
  }, [assignments]);

  return (
    <div className="Content">
      <H1 top="medium" left="x-large">
        Portal for godkjenning av betalingskortoppdrag.
      </H1>
      <H3 top="large" left="x-large">
        Start med å logge inn nedenfor
      </H3>
      <Button
        top="x-large"
        left="x-large"
        id="login-button"
        text="Logg inn"
        icon={linkOut}
        onClick={() => {
          window.location.assign(`${LOGIN_URL}`);
        }}
      />
    </div>
  );
};

export default LandingContent;
