import React from "react";
import Landing from "../components/Landing/Landing";
import LandingContent from "../components/Landing/LandingContent";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();
const IndexPage = () => (
  <QueryClientProvider client={queryClient}>
    <Landing>
      <LandingContent />
    </Landing>
  </QueryClientProvider>
);

export default IndexPage;
