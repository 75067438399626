import { request } from "./index"

export type Assignment = {
  AssignmentId: number
  OrganizationId: number
  FileHash: string
  FileName: string
  CollectedDate: Date
  Transactions: number
  TotalAmount: number
  Status: number
  Signatures: number
  MessageId: string
  Rejected: number
  FileType: string
  SignaturesNeeded: number
}

export async function fetchAssignments(): Promise<Assignment[]> {
  return request<Assignment[]>({
    method: `GET`,
    endpoint: `Assignment`,
  })
}

export async function cancelAssignment({ assignmentId }: { assignmentId: number }): Promise<{ assignmentId: number }> {
  return request<{ assignmentId: number }>({
    method: `PUT`,
    endpoint: `Assignment?assignmentId=${assignmentId}`,
  })
}
