import React from "react"
import Layout from "../Layout"
import "../../css/main.css"

type Props = {
  children: React.ReactNode
}

const Landing: React.FC<Props> = ({ children }: Props) => {
  return (
    <Layout>
      <div className="Landing">{children}</div>
    </Layout>
  )
}

export default Landing
